import React, {useContext, useState} from "react";

//Custom components
import {Breadcrumb} from "./Breadcrumb";

//Models
import {IStep} from "../models";

//Images
import logoWhite from "../assets/img/logo-white.svg";
import logoMobile from "../assets/img/logo-dark-mobile.svg";
import {SandwichButton} from "./UI/SandwichButton";
import {ResultsContext, StepContext} from "../contexts";

import arrowLeft from '../assets/img/icons/left-arrow.svg';
import arrowRight from '../assets/img/icons/right-arrow.svg';

interface IProps {
  steps: IStep[]
}

export const Header: React.FC<IProps> = ({steps}) => {
  const [isOpen, setOpenState] = useState<boolean>(false)
  const { selectedStep, nextStep, prevStep, allSteps, selectedStepIndex } = useContext(StepContext);
  const { priceBySquareUnit } = useContext(ResultsContext);

  return(
    <header className={isOpen ? "open" : ""}>
      <div className="container">
        <a href={"https://galeonart.com/"} target={"_blank"} className="logo">
          <img src={logoWhite} alt="" className={"desktop-logo"}/>
          <img src={logoMobile} alt="" className={"mobile-logo"}/>
        </a>
        <div className={"center mobile" + (selectedStepIndex === 0 ? " hide" : "")} >
          {selectedStepIndex !== 0 ? <div className={"navigation"} onClick={prevStep}>
            <img src={arrowLeft} alt=""/>
            Назад
          </div> : ""}
          <div className="price">
            <span>
              Матеріал і роботи
            </span>
            <strong>{Math.ceil(parseFloat(priceBySquareUnit?.toFixed(2) || "0"))} грн/м<sup>2</sup></strong>
          </div>
          <div className={"navigation" + (allSteps?.[(selectedStepIndex || 0) + 1]  ? "" : " hide")} onClick={nextStep}>
            <img src={arrowRight} alt=""/>
            Вперед
          </div>
        </div>
        <div className="wrap-breadcrumb">
          <Breadcrumb steps={steps} selectedItem={selectedStep && selectedStep.name} onChangeStep={() => setOpenState(false)}/>
          <SandwichButton isClicked={isOpen} onClick={() => setOpenState(!isOpen)}/>
        </div>
      </div>
    </header>
  )
}