import React, {useEffect, useState} from 'react';
import {IResult, IStep} from "./models";

//Contexts
import { ResultsContext, StepContext, ResponseContext } from './contexts';

//Custom components
import { DesignStep } from "./components/Design";
import { QuestionStep } from "./components/Question";

import {FixedFooter} from "./components/FixedFooter";
import {BigQuestion} from "./components/BigQuestion";
import {Header} from "./components/Header";
import {ResultStep} from "./components/Result";

//Images
import logo from "./assets/img/logo-black.svg";


let resultsGlobal: Record<string, IResult | IResult[]> = {};

const App = () => {
  const [steps, setSteps] = useState<IStep[]>([]);
  const [selectedStep, setSelectedStep] = useState<number>(0);
  const [results, setResults] = useState<Record<string, IResult | IResult[]>>({});
  const [responseURL, setResponseURL] = useState<string | null>(null);
  const [responseError, setResponseError] = useState<string | null>(null);

  useEffect(() => {
    fetch('data.json')
      .then((res) => res.json())
      .then((res) => {
        if(res.steps){
          setSteps(res.steps)
        }
      })
  }, [])

  useEffect(() => {
    document.querySelector('.wrap-steps')?.scrollTo(0, 0);
    const rightPathsOfDesign = document.querySelectorAll('.step.design .main-row .right');
    for(let i = 0; i < rightPathsOfDesign.length; i++){
      rightPathsOfDesign[i].scrollTo(0, 0)
    }
  }, [selectedStep])

  useEffect(() => {
    calculations()
  }, [results])

  const nextStep = () => {
    changeStep(selectedStep + 1)
  }

  const prevStep = () => {
    changeStep(selectedStep - 1 >= 0 ? selectedStep - 1 : 0)
  }

  const generateStep = (step: IStep, index: number) => {
    switch(step.type){
      case "bigQuestions":
        return <BigQuestion key={"step_" + step.name} step={step} isActive={index === selectedStep}/>
      case "design":
        return <DesignStep key={"step_" + step.name} step={step} isActive={index === selectedStep}/>
      case "questions":
        return <QuestionStep key={"step_" + step.name} step={step} isActive={index === selectedStep}/>
      case "results":
        return <ResultStep key={"step_" + step.name} step={step} isActive={index === selectedStep}/>
    }
  }

  const [summaryPrice, setSummaryPrice]  = useState<number>(0);
  const [summaryDays, setSummaryDays]  = useState<number>(0);

  const calculations = () => {
    let sum: number = 0;
    let sumDays: number = 0;
    for(let resultName in results){
      let currentRes = results[resultName];
      if(!Array.isArray(currentRes)){
        currentRes = [currentRes]
      }
      for(const result of currentRes){
        let count = 1;
        if(result.multiplicationBy){
          if(typeof result.multiplicationBy !== "undefined"){
            const multiplicationRes = results[result.multiplicationBy];
            if(!Array.isArray(multiplicationRes)){
              count = multiplicationRes.counts || (multiplicationRes?.value ? Number(multiplicationRes.value) : 1) || 1;
            }
          }
        }
        sum += (result.price || 0) * count * (result.counts || 1);
        sumDays += result.days || 0;
      }
    }
    setSummaryPrice(sum);
    setSummaryDays(sumDays);
  }

  const setResult = (key: string, result: IResult | IResult[] | false) => {
    if(result === false){
      delete resultsGlobal[key]
      setResults({
        ...resultsGlobal
      })
    } else {
      resultsGlobal = {
        ...resultsGlobal,
        [key]: result
      }
      setResults({
        ...resultsGlobal,
        [key]: result
      })
    }

  }

  const changeStep = (index: number) => {
    if(steps[selectedStep] && steps[selectedStep].questions){
      for(let question of steps[selectedStep].questions){
        if(Array.isArray(results?.[question.name]) ? false : results?.[question.name]?.isWrong) return;
      }
    }
    setSelectedStep(index)
  }

  return (
    <ResultsContext.Provider
      value={{
        results,
        setResult,
        summaryPrice,
        summaryDays,
        priceBySquareUnit: (summaryPrice || 0) / (parseFloat(Array.isArray(results?.['square']) ? '1' : results?.['square']?.value + '') || 1)
      }}>
      <ResponseContext.Provider value={{
        setResponseURL: setResponseURL,
        setResponseError: setResponseError
      }}>
        <StepContext.Provider
          value={{
            allSteps: steps,
            selectedStep: steps[selectedStep],
            selectedStepIndex: selectedStep,
            nextStep,
            prevStep,
            setSelectedStep: changeStep
          }}>
          <div className={"main-form" + ( selectedStep === 0 ? " add-bg" : "") + (!steps[selectedStep]?.hideFooter ? " show-footer" : "")}>
            <Header steps={steps} />
            <div className="wrap-steps">
              {steps.map((step, index) => (
                generateStep(step, index)
              ))}
            </div>
            <FixedFooter/>
          </div>
          <div className={"response" + (responseURL || responseError ? " show" : "")}>
            <img src={logo} className={"logo"} alt=""/>
            {responseURL && !responseError ? <div className="response__content">
              Дякуємо, що скористались нашим калькулятором. <br />
              Обрані вами матеріали та опції доступні в кошторисі.
              <a target={"_blank"} className={"btn"} href={responseURL}>Завантажити кошторис</a>
              <a className={"btn white with-border"} href={"https://galeonart.com/"}>Повернутися на головну</a>
              <p className={"small-text"}>*Кошторис також надіслано Вам на електронну адресу.<br />
                Якщо не знаходите повідомлення, можливо його позначено як спам.</p>
            </div> : ""}
            {responseError ? <div className="response__content">
              Щось пішло не так, спробуйте пізніше.
            </div> : ""}
          </div>
        </StepContext.Provider>
      </ResponseContext.Provider>
    </ResultsContext.Provider>
  );
}

export default App;
