import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {IQuestion} from "../../models";
import {ResultsContext} from "../../contexts";

interface IProps {
  question: IQuestion
}

export const InputNumber: React.FC<IProps> = ({question}) => {
  const [numberValue, setNumberValue] = useState<number | "">(typeof question.defaultValue === "number" ? question.defaultValue : 0);
  const [value, setValue] = useState<number | string>(question.defaultValue || 0);
  const { results, setResult } = useContext(ResultsContext);

  useEffect(() => {
    if(question.defaultValue){
      setResult && setResult(question.name, {
        value: question.defaultValue
      });
    }
  }, [])

  const validateFunction = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value ? e.target.value : "";
    const decimalValue = value.split('.')[1]
    if(decimalValue && decimalValue.length > 2){
      value = parseFloat(value).toFixed(2)
    }
    setValue(value)
    setNumberValue(parseFloat(value))
  }
  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(parseFloat(e.target.value).toFixed(2) || "1");
    setValueByNumberField(value);
  }

  const setValueByNumberField = (value: number | "") => {
    setNumberValue(value);
    setResults(value)
  }

  const setResults = (value: any) => {
    setValue(value);
    setResult && setResult(question.name, {
      value: value,
      isWrong: !!(question?.minValue && question.minValue > value)
    });
  }

  return (
    <label className={"field" + (question.minValue && numberValue < question.minValue ? " error" : "")}>
      <span className="placeholder">{question.placeholder}</span>
      <span className="right-path">
        <div className={"number-value" + ((parseFloat(numberValue + "") !== parseFloat(value + "")) ? " disabled" : "")}>
        {question.withArrows
          ? <div className={"with-arrows"}>
            <div className="btn-control minus" onClick={() => {
              setValueByNumberField((numberValue || 0) - 1 || 1)
            }}/>
            <div className="like-input">
              {numberValue}
            </div>
            <div className="btn-control plus" onClick={() => {
              setValueByNumberField((numberValue || 0) + 1)
            }}/>
          </div>
          : <input type="text" value={value} onChange={validateFunction} onBlur={handleBlur}/>
        }
      </div>
        {question.variants && question.variants.map((variant) => {
          return (
            <div
              key={variant.value}
              className={
                "variant"
                + (value === variant.value ? " selected" : "")
              }
              onClick={() => {
                setResults(variant.value)
              }}>
              {variant.label}
            </div>
          )
        })}
        {question.afterInput && <span dangerouslySetInnerHTML={{__html: question.afterInput}}/>}
        <span className="error-message">
          Мінімальне значеня - {question.minValue}
        </span>
      </span>
    </label>
  );
}