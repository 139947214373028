import React, {useContext} from "react";

import {InputNumber} from "./fields/InputNumber";
import {InputText} from "./fields/InputText";
import {InputRadio} from "./fields/InputRadio";

import {IQuestion, IResult, IStep} from "../models";

import {ResultsContext, StepContext} from "../contexts";

interface IProps {
  step: IStep
  isActive: boolean
}

export const QuestionStep: React.FC<IProps> = ({step, isActive}) => {
  const {nextStep} = useContext(StepContext);
  const {results} = useContext(ResultsContext);

  const generateInput = (question: IQuestion) => {
    switch(question.type){
      case "text":
        return <InputText key={question.name} question={question}/>
      case "radio":
        return <InputRadio key={question.name} question={question}/>
      case "number":
      default:
        return <InputNumber key={question.name} question={question}/>
    }
  }

  const nextStepInner = () => {
    const res = validate(step, results);
    if(res){
      nextStep && nextStep()
    }
  }

  const validate = ( step: IStep, results?: Record<string, IResult | IResult[]>): boolean => {
    let res = true;
    for(let question of step.questions){
      if(question){
        if(question.required && results){
          const result = results[question.name];

          if(!result ||
            (Array.isArray(result) ? result.length === 0 : false) ||
            (!Array.isArray(result) ? !result.value : false)){
            res = false;
          }
        }
        if(question.minValue
          && results?.[question.name]
          && (Array.isArray(results?.[question.name]) ? false : question.minValue > (results?.[question.name]?.value || 0)))
        {
          res = false;
        }
      }
    }
    return res;
  }

  return (
    <div className={'step question' + (isActive ? " active" : "")}>
      <div className="step-content">
        <h2>{step.title}</h2>
        {step.questions && step.questions.map((question) => {
          if(question.disable) return '';
          return generateInput(question)
        })}
        <div className="center">
          <div className="btn" onClick={nextStepInner}>
            Перейти до дизайну
          </div>
        </div>
      </div>
    </div>
  )
}