import React, {useContext, useState} from "react";
import MaskedInput from "react-text-mask";
import {SERVER_ENDPOINT} from "../config";


import {IResult, IStep, ISubVariant} from "../models";
import {ResponseContext, ResultsContext, StepContext} from "../contexts";

import iconTg from "../assets/img/social/telegram.svg";
import iconVb from "../assets/img/social/viber.svg";
import iconWa from "../assets/img/social/whatsapp.svg";
import iconEmail from "../assets/img/social/email.svg";

interface IProps {
  step: IStep
  isActive: boolean
}

const socials = [
  {
    name: "viber",
    title: "Viber",
    icon: iconVb
  },
  {
    name: "telegram",
    title: "Telegram",
    icon: iconTg
  },
  {
    name: "whatsApp",
    title: "WhatsApp",
    icon: iconWa
  },
  {
    name: "email",
    title: "Email",
    icon: iconEmail
  }
]
const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ResultStep:React.FC<IProps> = ({isActive, step}) => {
  const { summaryDays, summaryPrice, results, priceBySquareUnit } = useContext(ResultsContext);
  const { allSteps } = useContext(StepContext);
  const { setResponseError, setResponseURL } = useContext(ResponseContext);
  const [selectedSocial, setSelectedSocial] = useState<string>('viber');
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  // const [errorStatusEmail, setErrorStatusEmail] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('+38');
  // const [email, setEmail] = useState<string>('');


  const sendResult = () => {
    setShowError(true);
    if(phoneNumber.length !== 17){
      setErrorStatus(true);
      return;
    }
    // if(!email || !re.test(String(email).toLowerCase())){
    //   setErrorStatusEmail(true);
    //   return;
    // }
    if(results){
      let fullResults: { name: string; sectionTitle: string; questionName: string | undefined; value: string; color: string | undefined; counts: string | number | true | undefined; price: number | undefined; multiplicationBy: string | undefined; }[] = [];
      let fullSpecifications = [];
      for(let resKey in results){
        let questionName: string = "";
        let designName: string = "";
        const result = results[resKey];
        let keys = resKey.split('_-_');
        if(keys[1]){
          designName = keys[0];
          questionName = keys[1];
        } else {
          questionName = keys[0];
        }
        allSteps?.forEach(step => {
          if(designName && step.name !== designName) return;
          if(step.questions){
            let question = step.questions.find((question) => {
              return question.name === questionName;
            });
            if(question){
              if(!Array.isArray(result)){
                result.title = designName ? step.title + " " + question.placeholder : question.placeholder;
                if(question.variants){
                  const variant = question.variants.find((variant) => (
                    result.value === variant.value
                  ))
                  result.subValue = variant?.label || String(result.value || "")
                } else {
                  result.subValue = String(result.value || "");
                }
                if(step.name === "info"){
                  fullSpecifications.push({
                    title: question?.placeholder,
                    value: result.subValue,
                    type: question.name === "square" ? "square" : (question.name === "name" ? "name" : "other")
                  })
                } else {
                  let multiplicationItem: IResult | IResult[] | undefined;
                  if(result.multiplicationBy){
                    multiplicationItem = results[result.multiplicationBy]
                  }
                  fullResults.push({
                    name: question.name,
                    sectionTitle: step.title,
                    questionName: question?.placeholder,
                    value: result.subValue,
                    color: '',
                    counts: result.counts || (multiplicationItem ? (Array.isArray(multiplicationItem) ? 1 : multiplicationItem?.value || 1) : 1),
                    price: result.price,
                    multiplicationBy: result.multiplicationBy
                  })
                }
              } else {
                result.forEach((res) => {
                  res.title = designName ? step.title + " " + question?.placeholder : question?.placeholder;
                  if(question?.variants){
                    const variant = question?.variants.find((variant) => (
                      res.value === variant.value
                    ))
                    res.subValue = variant?.label || String(res.value || "")
                  } else {
                    res.subValue = String(res.value || "");
                  }
                  if(step.name === "info"){
                    fullSpecifications.push({
                      title: question?.placeholder,
                      value: res.subValue
                    })
                  } else {
                    if(question){
                      let multiplicationItem: IResult | IResult[] | undefined;
                      if(res.multiplicationBy){
                        multiplicationItem = results[res.multiplicationBy]
                      }
                      fullResults.push({
                        name: question.name,
                        sectionTitle: step.title,
                        questionName: question?.placeholder,
                        value: res.subValue,
                        color: '',
                        counts: res.counts || multiplicationItem ? (Array.isArray(multiplicationItem) ? 1 : multiplicationItem?.value || 1) : 1,
                        price: res.price,
                        multiplicationBy: res.multiplicationBy
                      })
                    }
                  }
                })
              }
            }

          }
          if(step.groupsVariants){
            step.groupsVariants.forEach((groupVariant) => {
              let subVariant:ISubVariant | undefined;
              if(groupVariant.name !== questionName) return;
              groupVariant.variants.forEach((variant) => {
                subVariant = variant.subVariants.find((subVariant) => {
                  if(Array.isArray(result)) {
                    for(const res of result){
                      return (res.value === subVariant.value)
                    }
                  } else {
                    return result.value === subVariant.value
                  }
                })
                if(subVariant){
                  let multiplicationItem: IResult | IResult[] | undefined;
                  if(groupVariant.multiplicationBy){
                    multiplicationItem = results[groupVariant.multiplicationBy]
                  }
                  let resList: IResult[];
                  if(Array.isArray(result)){
                    resList = result;
                  } else {
                    resList = [result];
                  }
                  resList.forEach((res) => {
                    res.title = designName ? step.title + " " + groupVariant.title : groupVariant.title;
                    res.subTitle = variant.title;
                    res.subValue = subVariant?.color;
                    fullResults.push({
                      name: groupVariant.name,
                      sectionTitle: step.title,
                      questionName: groupVariant.title,
                      value: variant.title,
                      color: res.subValue,
                      counts: groupVariant.multiplicationBy ? (Array.isArray(multiplicationItem) ? 1 : multiplicationItem?.value || 1) : 1,
                      price: res.price,
                      multiplicationBy: groupVariant.multiplicationBy
                    })
                  })
                }
              })
            })
          }
        })
      }
      fullSpecifications.push({
        title: "Разом",
        value: summaryPrice,
        type: "price"
      })
      fullSpecifications.push({
        title: "Ціна за 1 м2 ",
        value: priceBySquareUnit,
        type: "price_sqm"
      })

      fetch(SERVER_ENDPOINT + "/api/", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          social: selectedSocial,
          phoneNumber,
          email: '      ',
          results,
          fullResults,
          fullSpecifications,
          days: summaryDays,
          price: summaryPrice,
          priceBySqm: Math.ceil(priceBySquareUnit || 0)
        })
      })
        .then((res) => res.json())
        .then(res => {
          sessionStorage['responseURL'] = res.url;
          window.location.href = '/thank-you.html'
          setResponseURL && setResponseURL(res.url);

        })
        .catch((e) => {
          sessionStorage['responseError'] = 'Something was wrong, try again later';
          window.location.href = '/thank-you.html'
          setResponseError && setResponseError('Something was wrong, try again later');
        });
    }
  }

  return (
    <>
      <div className={'step result' + (isActive ? " active" : "")}>
        <div className="step-content">
          <h2>Ремонт з нами просто!</h2>
          <div className="block-result__wrap">
            <div className="block-result">
              <div className="block-result__left">
                <div className="block-result__top">
                  <div className="block-result__top__item summary">
                    Разом: <span>{Math.ceil(parseFloat(summaryPrice?.toFixed(2) || "0"))} грн.</span>
                  </div>
                  <div className="block-result__top__item summary-by-square">
                    Вартість: <span>{Math.ceil(parseFloat(priceBySquareUnit?.toFixed(2) || "0"))} грн/м<sup>2</sup></span>
                  </div>
                  <div className="block-result__top__item summary-time">
                    Термін ремонту: <span>{Math.ceil(summaryDays || 0)} днів</span>
                  </div>
                </div>
                <div className="block-result__bottom">
                  <ul className={"block-result__bottom__list"}>
                    {step.staticList?.map((staticInfo) => (
                      <li className={"block-result__bottom__item"}>{staticInfo}</li>
                    ))}
                  </ul>
                </div>
                <div className="css-custom-block"></div>
              </div>
              <div className="result-form">
                <h4>Кошторис</h4>
                <p>Залиште номер телефону, і ми надішлемо вам кошторис</p>
                <div className="select-soc">
                  {socials.map((social) => (
                    <div className={"select-soc__item" + (social.name === selectedSocial ? " selected" : "")} onClick={() => {
                      setSelectedSocial(social.name)
                    }}>
                      <div className="select-soc__item__inner">
                        <div className="left">
                          <div className="icon-check"/>
                          <span>{social.title}</span>
                        </div>
                        <img src={social.icon} alt=""/>
                      </div>
                    </div>
                  ))}
                </div>
                <label className={"result-form__phone" + ( showError && errorStatus ? " error" : "")}>
                  <span>Телефон: </span>
                  <MaskedInput
                    mask={['+', '3', '8', '(', '0', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                    type={"tel"}
                    name={"phone"}
                    placeholder={'+38 (000) 000 0000'}
                    onChange={
                      (e) => {
                        let text = e.target.value.replace(/_/g, '');

                        if(text.length !== 17){
                          setErrorStatus(true);
                        } else {
                          setErrorStatus(false);
                        }
                        setPhoneNumber(text)
                      }
                    }
                    value={phoneNumber}
                  />
                  <span className="error-message">Введіть коректний номер телефону</span>
                </label>
                {/*<label className={"result-form__email" + ( showError && errorStatusEmail ? " error" : "")}>*/}
                {/*  <span>Email: </span>*/}
                {/*  <input type={"email"}*/}
                {/*         name={"email"}*/}
                {/*    onChange={*/}
                {/*      (e) => {*/}
                {/*        let text = e.target.value;*/}

                {/*        if(!text || !re.test(String(text).toLowerCase())){*/}
                {/*          setErrorStatusEmail(true);*/}
                {/*        } else {*/}
                {/*          setErrorStatusEmail(false);*/}
                {/*        }*/}
                {/*        setEmail(text)*/}
                {/*      }*/}
                {/*    }*/}
                {/*    value={email}*/}
                {/*  />*/}
                {/*  <span className="error-message">Введіть коректний емейл</span>*/}
                {/*</label>*/}
                <div className="btn white" onClick={sendResult}>Завантажити кошторис</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
