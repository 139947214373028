import React from "react";

import {IQuestion, IStep} from "../models";

import {BigInputCheckbox} from "./fields/BigInputCheckbox"
import {BigInputRadio} from "./fields/BigInputRadio";
import {SimpleCheck} from "./fields/SimpleCheck";

interface IProps {
  step: IStep,
  isActive: boolean
}

export const BigQuestion: React.FC<IProps> = ({step, isActive}) => {
  const generateInput = (question: IQuestion) => {
    switch(question.type){
      case "radio":
        return <BigInputRadio key={question.name} question={question}/>
      case "checkbox":
        return <BigInputCheckbox key={question.name} question={question}/>
      case "simpleCheck":
      default:
        return <SimpleCheck key={question.name} question={question}/>
    }
  }

  return (
    <div className={'step question-big' + (isActive ? " active" : "")}>
      <h2>{step.title}</h2>
      {!!step.subtitle && <h4>{step.subtitle}</h4>}
      <div className="big-questions">
        {step.questions && step.questions.map((question) => {
          return generateInput(question)
        })}
      </div>
    </div>
  )
}