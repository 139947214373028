import React, {useContext, useEffect, useState} from "react";
import {IQuestion, IResult} from "../../models";
import {ResultsContext} from "../../contexts";
import {Counts} from "../UI/Counts";

interface IProps {
  question: IQuestion
}

export const BigInputCheckbox: React.FC<IProps> = ({question}) => {
  const { results, setResult } = useContext(ResultsContext);
  const currentQuestionAnswer = results?.[question.name];

  const [isChecked, setCheckedStatus] = useState<boolean>(Array.isArray(currentQuestionAnswer) && currentQuestionAnswer.length ? true : question.defaultChecked || false);

  const defaultValue = question.defaultValue || "";


  useEffect(() => {
    if(question.defaultValue){
      const value = question.defaultValue
      setResult && setResult(question.name, {
        value: value,
        price: question.variants?.find(variant => variant.value === value)?.price,
        days: question.variants?.find(variant => variant.value === value)?.days,
        multiplicationBy: question.multiplicationBy
      });
    }
  }, [])

  const setResults = (curValue: any, count?: any) => {
    const currentQuestionAnswer = results?.[question.name];
    const newValue = {
      value: curValue,
      counts: count,
      price: question.variants?.find(variant => variant.value === curValue)?.price,
      days: question.variants?.find(variant => variant.value === curValue)?.days,
      multiplicationBy: question.multiplicationBy
    };


    let newAnswer:IResult[] = [];
    if(curValue !== false){
      if(currentQuestionAnswer){
        if(Array.isArray(currentQuestionAnswer)){
          if(currentQuestionAnswer.find((answer) => answer.value === curValue)){
            newAnswer = currentQuestionAnswer.filter((answer) => answer.value !== curValue)
          } else {
            newAnswer = [...currentQuestionAnswer, newValue]
          }
        }
      } else {
        newAnswer = [
          newValue
        ]
      }
    }
    if(newAnswer.length){
      setResult && setResult(question.name, newAnswer);
    } else {
      setResult && setResult(question.name, false);
    }
  }

  return (
    <div className={"question"}>
      <label className="question__top">
        <input type="checkbox" name={question.name} checked={isChecked} onChange={(e) => {
          if(isChecked){
            setResults(false);
          }
          setCheckedStatus(!isChecked);

        }}/>
        <span className="question__placeholder">{question.placeholder}</span>
      </label>
      <span className={"question__variants" + (isChecked ? " open" : "")}>
        {question.variants && question.variants.map((variant) => {
          return (
            <div
              key={variant.value}
              className={
                "question__variant"
                + (Array.isArray(currentQuestionAnswer) && currentQuestionAnswer.find(answer => answer.value === variant.value) ? " selected" : "")
              }
              onClick={() => {
                 setResults(variant.value)
              }}
            >
              <div className="question__variant__top">
                {variant.img ? <img src={variant.img} alt=""/> : ""}
                <div className="bottom-row">
                  <span className={"question__variant__title"}>{variant.label}</span>
                  {question.withCounts
                    ? <Counts isCheckedVariant={!!(Array.isArray(currentQuestionAnswer) && currentQuestionAnswer.find(answer => answer.value === variant.value))} onChange={(count) => setResults(variant.value, count)}/>
                    : ""
                  }
                </div>
              </div>
              <div className="question__variant__price">
                + {Math.ceil(variant.price || 0)} грн
              </div>
            </div>
          )
        })}
      </span>
    </div>
  );
}