import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {IQuestion} from "../../models";
import {ResultsContext} from "../../contexts";

interface IProps {
  question: IQuestion
}

export const InputText: React.FC<IProps> = ({question}) => {
  const [value, setValue] = useState<string>("");
  const { results, setResult } = useContext(ResultsContext);

  useEffect(() => {
    if(question.defaultValue){
      setResult && setResult(question.name, {
        value: question.defaultValue
      });
    }
  }, [])

  const setResults = (value: any) => {
    setValue(value);
    setResult && setResult(question.name, {
      value: value
    });
  }
  const validateFunction = (e: ChangeEvent<HTMLInputElement>) => {
    setResults(e.target.value);
  }

  return (
    <label className={"field text"}>
      <input type="text" value={value} onChange={validateFunction} placeholder={question.placeholder}/>
    </label>
  );
}