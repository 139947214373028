import React, {useContext, useEffect, useState} from "react";

import {IGroupVariant, IStep} from "../models";

import { SelectedVariantsContext, CurrentStepContext } from './contexts'
import {DesignVariant} from "./fields/DesignVariant";
import {ResultsContext} from "../contexts";

interface IProps {
  step: IStep,
  isActive: boolean
}

export type SelectedValueType = Record<string, Record<string, string>>

let selectedValuesGlobal = {};

export const DesignStep: React.FC<IProps> = ({step, isActive}) => {
  const [selectedValues, setSelectedValues] = useState<SelectedValueType>({})
  const { setResult } = useContext(ResultsContext)

  useEffect(() => {
    const groupsVariants = step.groupsVariants;
    for(let groupVariants of groupsVariants){
      for(let variant of groupVariants.variants){
        for(let subVariant of variant.subVariants){
          if(subVariant.defaultSelected){
            selectDesignVariant({
              [groupVariants.name]: {
                [variant.name]: subVariant.value
              }
            }, step.name)
          }
        }
      }
    }
  }, [])

  const selectDesignVariant = (selectedValue: SelectedValueType, stepName?: string): void => {
    for(let groupVariantName in selectedValue){
      const groupVariant = step.groupsVariants.find(groupVariants => groupVariants.name === groupVariantName);
      if(selectedValue.hasOwnProperty(groupVariantName)) {
        for(let variantName in selectedValue[groupVariantName]){
          if(selectedValue[groupVariantName].hasOwnProperty(variantName)) {
            const variant = groupVariant?.variants.find(variant => variant.name === variantName);
            setResult && setResult((stepName ? stepName : step.name) + "_-_" + groupVariantName, {
              value: selectedValue[groupVariantName][variantName],
              price: (variant?.price || 0) + (groupVariant?.basicPrice || 0),
              days: variant?.days,
              multiplicationBy: groupVariant?.multiplicationBy
            })
          }
        }
      }
    }

    selectedValuesGlobal = {
      ...selectedValuesGlobal,
      ...selectedValue
    }

    setSelectedValues(selectedValuesGlobal)
  }

  const generateVariantSelect = (groupVariants: IGroupVariant) => {
    return <DesignVariant groupVariants={groupVariants}/>
  }

  return (
    <div className={'step design' + (isActive ? " active" : "")}>
      <div className="main-row">
        <div className="left design">
          {
            step.designLayouts.map((designLayout) => (
              <img src={designLayout.img} style={{zIndex: designLayout.zIndex}} alt=""/>
            ))
          }
          {
            step.groupsVariants.map((groupVariants) => (
              <>
                {
                  groupVariants.variants.map(variant => (
                    <>
                      {variant.subVariants.map(subVariant => (
                        <img
                          src={subVariant.image}
                          alt=""
                          key={"image_" + subVariant.image}
                          className={"flat_image" + (selectedValues?.[groupVariants.name]?.[variant.name] === subVariant.value ? " selected": "")}
                        />
                      ))}
                    </>
                  ))
                }
              </>
            ))
          }
        </div>
        <div className="right">
          <h2>{step.title}</h2>
          <CurrentStepContext.Provider value={{step}}>
            <SelectedVariantsContext.Provider value={{selectDesignVariant, selectedDesignVariants: selectedValues}}>
              {step.groupsVariants && step.groupsVariants.map((groupVariants) => {
                return generateVariantSelect(groupVariants)
              })}
            </SelectedVariantsContext.Provider>
          </CurrentStepContext.Provider>
        </div>
      </div>
    </div>
  )
}