import React from "react";
import {SelectedValueType} from "../Design";
import {IStep} from "../../models";

interface ISelectedVariantsContext {
  selectedDesignVariants: SelectedValueType
  selectDesignVariant: (selectedValue: SelectedValueType) => void;
}

interface ICurrentStepContext {
  step?: IStep
}


export const SelectedVariantsContext = React.createContext<Partial<ISelectedVariantsContext>>({
  selectDesignVariant: () => {},
  selectedDesignVariants: {}
});

export const CurrentStepContext = React.createContext<Partial<ICurrentStepContext>>({});