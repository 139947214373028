import React, {useContext, useEffect, useState} from "react";
import {IQuestion} from "../../models";
import {ResultsContext} from "../../contexts";

interface IProps {
  question: IQuestion
}

export const SimpleCheck: React.FC<IProps> = ({question}) => {
  const [value, setValue] = useState<boolean>(question.defaultChecked || false);
  const { setResult } = useContext(ResultsContext);

  useEffect(() => {
    if(question.defaultValue){
      setResult && setResult(question.name, {
        value: question.defaultValue
      });
    }
  }, [])

  const setResults = (value: boolean) => {
    setValue(value);
    if(value){
      setResult && setResult(question.name, {
        value: value,
        price: question.price,
        days: question.days
      });
    } else {
      setResult && setResult(question.name, false);
    }
  }

  return (
    <div className={"question"}>
      <label className="question__top">
        <input type="checkbox" name={question.name} checked={value} onChange={(e) => {
          if(e.target.checked){
            setResults(true)
          } else {
            setResults(false)
          }
        }}/>
        <span className="question__placeholder">{question.placeholder}</span>
        <span className="question__price">
          + {Math.ceil(question.price || 0)} грн
        </span>
      </label>
    </div>
  );
}