import React from "react";
import {IResult, IStep} from "./models";

interface IResultsContext {
  setResult: (key: string, result: IResult | IResult[] | false) => void;
  results: Record<string, IResult | IResult[]>;
  summaryDays: number;
  summaryPrice: number;
  priceBySquareUnit: number;
}

interface IStepContext {
  allSteps: IStep[];
  selectedStep?: IStep;
  selectedStepIndex: number;
  nextStep: () => void;
  prevStep: () => void;
  setSelectedStep: (stepIndex: number) => void
}

interface IResponseContext {
  setResponseError: (error: string) => void;
  setResponseURL: (url: string) => void
}


export const ResultsContext = React.createContext<Partial<IResultsContext>>({
  setResult: () => (''),
  results: {},
  summaryDays: 0,
  summaryPrice: 0,
  priceBySquareUnit: 0
});

export const ResponseContext = React.createContext<Partial<IResponseContext>>({
  setResponseError: () => (''),
  setResponseURL: () => ('')
});

export const StepContext = React.createContext<Partial<IStepContext>>({
  selectedStepIndex: 0,
  setSelectedStep: () => (''),
  nextStep: () => (''),
  prevStep: () => ('')
})