import React, {useContext} from "react";
import {IStep} from "../models";
import {ResultsContext, StepContext} from "../contexts";

interface IProps {
  steps: IStep[],
  selectedItem?: string;
  onChangeStep: Function;
}

export const Breadcrumb: React.FC<IProps> = ({steps, selectedItem, onChangeStep}) => {
  const { setSelectedStep, selectedStep } = useContext(StepContext);
  const { results } = useContext(ResultsContext);
  return (
    <div className={"breadcrumb"}>
      <div className="breadcrumb__row">
        {steps.map(
          (step, index) =>
            <div
              key={step.name}
              onClick={() => {
                setSelectedStep && setSelectedStep(index);
                onChangeStep()
              }}
              className={"breadcrumb__item" + (selectedItem === step.name ? " selected": "")}
            >
              {step.label}
            </div>
          )
        }
      </div>
    </div>
  )
}