import React from "react";
import {IGroupVariant} from "../../models";
import {VariantSelect} from "./VariantSelect";

interface IProps {
  groupVariants: IGroupVariant
}

export const DesignVariant: React.FC<IProps> = ({groupVariants}) => {
  return(
    <div className={"variant__group"}>
      <h3>{groupVariants.title}</h3>
      {groupVariants.variants.map((variant) => (
        <VariantSelect key={variant.name} variant={variant} groupVariants={groupVariants}/>
      ))}
    </div>
  )
}