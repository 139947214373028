import React, {useContext, useEffect, useState} from "react";
import {IQuestion} from "../../models";
import {ResultsContext} from "../../contexts";

interface IProps {
  question: IQuestion
}

export const InputRadio: React.FC<IProps> = ({question}) => {
  const [value, setValue] = useState<number | string>(question.defaultValue || "");
  const { results, setResult } = useContext(ResultsContext);

  useEffect(() => {
    if(question.defaultValue){
      setResult && setResult(question.name, {
        value: question.defaultValue
      });
    }
  }, [])

  const setResults = (value: any) => {
    setValue(value);
    setResult && setResult(question.name, {
      value: value
    });
  }

  return (
    <label className={"field"}>
      <span className="placeholder">{question.placeholder}</span>
      <span className="right-path">
        {question.variants && question.variants.map((variant) => {
          return (
            <div
              key={variant.value}
              className={
                "variant"
                + (value === variant.value ? " selected" : "")
              }
              onClick={() => {
                setResults(variant.value)
              }}
            >
              {variant.label}
            </div>
          )
        })}
      </span>
    </label>
  );
}