import React, {useContext, useEffect, useState} from "react";
import {IQuestion} from "../../models";
import {ResultsContext} from "../../contexts";
import {Counts} from "../UI/Counts";

interface IProps {
  question: IQuestion
}

export const BigInputRadio: React.FC<IProps> = ({question}) => {
  const [isChecked, setCheckedStatus] = useState<boolean>(question.defaultChecked || false);
  const [value, setValue] = useState<number | string | false>(question.defaultValue || "");
  const { setResult } = useContext(ResultsContext);

  useEffect(() => {
    if(question.defaultValue){
      const value = question.defaultValue
      setResult && setResult(question.name, {
        value: value,
        price: question.variants?.find(variant => variant.value === value)?.price,
        days: question.variants?.find(variant => variant.value === value)?.days,
        multiplicationBy: question.multiplicationBy
      });
    }
  }, [])

  const setResults = (value: any, count?: any) => {
    setCheckedStatus(!!value);
    setValue(value);
    if(value){
      setResult && setResult(question.name, {
        value: value,
        counts: count,
        price: question.variants?.find(variant => variant.value === value)?.price,
        days: question.variants?.find(variant => variant.value === value)?.days,
        multiplicationBy: question.multiplicationBy
      });
    } else {
      setResult && setResult(question.name, false);
    }
  }

  return (
    <div className={"question"}>
      <label className="question__top">
        <input type="checkbox" name={question.name} checked={isChecked} onChange={(e) => {
          if(e.target.checked){
            setResults(question.variants?.[0].value, question.withCounts ? 1 : undefined)
          } else {
            setResults(false)
          }
        }}/>
        <span className="question__placeholder">{question.placeholder}</span>
      </label>
      <span className={"question__variants radio" + (isChecked ? " open" : "")}>
        {question.variants && question.variants.map((variant) => {
          return (
            <div
              key={variant.value}
              className={
                "question__variant"
                + (value === variant.value ? " selected" : "")
              }
              onClick={() => {
                value !== variant.value && setResults(variant.value)
              }}
            >
              <div className="question__variant__top">
                {variant.img ? <img src={variant.img} alt=""/> : ""}
                <div className="bottom-row">
                  <span className={"question__variant__title"}>{variant.label}</span>
                  {question.withCounts
                    ? <Counts isCheckedVariant={value === variant.value} onChange={(count) => setResults(variant.value, count)}/>
                    : ""
                  }
                </div>
              </div>
              <div className="question__variant__price">
                + {Math.ceil(variant.price || 0)} грн
              </div>
            </div>
          )
        })}
      </span>
    </div>
  );
}