import React, {useContext} from "react";
import { IGroupVariant, ISubVariant, IVariant } from "../../models";
import {SelectedVariantsContext} from "../contexts";

interface IProps {
  subVariant: ISubVariant,
  variant: IVariant,
  groupVariants: IGroupVariant
}

export const SubVariantSelect: React.FC<IProps> = ({subVariant, groupVariants, variant}) => {
  const { selectDesignVariant, selectedDesignVariants} = useContext(SelectedVariantsContext)
  return(
    <>
      <div
        className={"icon-color" + ((selectedDesignVariants?.[groupVariants.name]?.[variant.name]) === subVariant.value ? " selected" : "")}
        style={{background: subVariant.color}}
        onClick={() => {
          selectDesignVariant && selectDesignVariant({
            [groupVariants.name]: {
              [variant.name]: subVariant.value
            }})
      }} />
    </>
  )
}