import React, {useContext, useEffect, useState} from "react";
import {ResultsContext, StepContext} from "../contexts";
import {ArrowRight} from "../assets/img/icons/Arrow-right";

interface IProps {
}

export const FixedFooter: React.FC<IProps> = () => {
  const { results, priceBySquareUnit, summaryDays } = useContext(ResultsContext);
  const { selectedStep, selectedStepIndex, nextStep, prevStep, allSteps } = useContext(StepContext);

  return (
    <footer className={"footer" + (selectedStep?.hideFooter ? "" : " show")}>
      <p className={"price"}>Матеріали і роботи: <span>{Math.ceil(parseFloat(priceBySquareUnit?.toFixed(2) || "0"))} грн/м<sup>2</sup></span></p>
      <div className="right-path">
        <div className="btn white with-border" onClick={prevStep}>
          Назад
        </div>
        <div className="btn" onClick={nextStep}>
          {allSteps?.[(selectedStepIndex || 0) + 1]?.title}
        </div>
      </div>
    </footer>
  )
}