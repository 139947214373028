import React, {useEffect, useState} from "react";

interface IProps {
  defaultCount?: number,
  onChange?: (newValue: number) => void,
  isCheckedVariant?:boolean
}


export const Counts: React.FC<IProps> = ({onChange, defaultCount, isCheckedVariant}) => {
  const [value, setValue] = useState<number>(defaultCount || 1)

  useEffect(() => {
    if(isCheckedVariant === true){
      onChange && onChange(value)
    }
  }, [isCheckedVariant])

  const setValueByNumberField = (newValue: number) => {
    onChange && onChange(newValue)
    setValue(newValue)
  }

  return (
    <div className={"counts"}>
      <div className="btn-control minus" onClick={() => {
        setValueByNumberField(value - 1 || 1)
      }}/>
      <div className="like-input">
        {value}
      </div>
      <div className="btn-control plus" onClick={() => {
        setValueByNumberField(value + 1)
      }}/>
    </div>
  )
}