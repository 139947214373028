import React, {useContext} from "react";
import { IGroupVariant, IVariant} from "../../models";
import {SubVariantSelect} from "./SubVariantSelect";
import {CurrentStepContext, SelectedVariantsContext} from "../contexts";
import {ResultsContext} from "../../contexts";

interface IProps {
  variant: IVariant,
  groupVariants: IGroupVariant
}

export const VariantSelect: React.FC<IProps> = ({variant, groupVariants}) => {
  const { results } = useContext(ResultsContext)
  const { selectDesignVariant, selectedDesignVariants} = useContext(SelectedVariantsContext)
  const { step } = useContext(CurrentStepContext)

  return(
    <div className={"variant__block"}>
      <input type="radio" name={step?.name+ "_" + groupVariants.name} value={variant.name} onChange={() => {
        selectDesignVariant && selectDesignVariant({
          [groupVariants.name]: {
            [variant.name]: variant.subVariants[0]?.value
          }})
      }} checked={!!(selectedDesignVariants?.[groupVariants.name]?.[variant.name])}/>
      <div className="variant__inner">
        <h4 onClick={() => {
          selectDesignVariant && selectDesignVariant({
            [groupVariants.name]: {
              [variant.name]: variant.subVariants[0]?.value
            }})
        }}>{variant.title}</h4>
        <div className="variant__content">
          <h5>{variant.subtitle}</h5>
          <div className="row">
            <div className="variant__colors">
              {variant.subVariants.map((subVariant) => (
                <SubVariantSelect
                  key={groupVariants.name + "_" + variant.name + "_" + subVariant.value}
                  subVariant={subVariant}
                  variant={variant}
                  groupVariants={groupVariants}
                />
              ))}
            </div>
            <div className="price">
              + {Math.ceil(variant.price * (groupVariants.multiplicationBy ? (Array.isArray(results?.[groupVariants.multiplicationBy]) ? 1 : results?.[groupVariants.multiplicationBy]?.value ? Number(results?.[groupVariants.multiplicationBy].value) : 1) : 1))} грн
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}