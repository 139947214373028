import React from "react";

interface IProps {
  isClicked: boolean,
  onClick: Function
}

export const SandwichButton: React.FC<IProps> = ({isClicked, onClick}) => {
  return(
    <div className="sandwich-button" onClick={() => onClick()}>
      <i/><i/><i/>
    </div>
  )
}